<template>
	<div class="doctor">
		<!-- 背景 -->
		<div class="EMS_bg">
			<the-nav></the-nav>
			<div class="EMS_bg_bottom">
				<img
					class="bg_header"
					src="../../assets/imgs/img-product-c.png"
					alt="储电医生"
				/>
				<img
					class="bg_logo"
					src="../../assets/imgs/img-logo-c.png"
					alt="储电医生"
				/>
				<h1>
					24小时在线的 <br />
					电池安全服务
				</h1>
				<h6>AI人工智能监控电池数据波动，发现“生病”的电池</h6>
				<div class="yuyue_btn yuyue_btn2" @click="booking">
					预约体验
				</div>
			</div>
		</div>
		<!-- 0 -->
		<div class="EMS_box bg_white">
			<div class="EMS_box_con0">
				<div class="zero_title">针对储能电池算法优化</div>
				<div class="common_title align_center">
					<span class="cn_title">常见异常全面捕获</span>
					<div class="en_title">
						同时给出运维建议，指导运维人员妥善及时处理
					</div>
				</div>
				<div class="grid">
					<div
						class="grid_item"
						v-for="(item, index) in suanfa"
						:key="'suanfa' + index"
					>
						<div class="svg">
							<svg class="svg_img" aria-hidden="true">
								<use :xlink:href="`#${item.svg}`"></use>
							</svg>
						</div>

						<div class="bottom">{{ item.name }}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 1 -->
		<div class="EMS_box bg_blue">
			<div class="EMS_box_con1">
				<div class="top">
					<div class="common_title common_title2">
						<span class="cn_title" style="color: #fff">
							安全状态 尽在掌握
						</span>
					</div>
					<div class="top_description">
						定期自动诊断，推送诊断报告
						<br />
						手动开启立即诊断，实时了解电池状态
						<br />
						自由调取诊断记录和详细
					</div>
				</div>
				<div class="left">
					<img
						src="../../assets/imgs/img-doctor-picture-a.png"
						alt="安全"
					/>
				</div>
			</div>
		</div>
		<!-- 2 -->
		<div class="EMS_box">
			<div class="EMS_box_con1">
				<div class="top">
					<div class="common_title">
						<span class="cn_title" style="color: #0c0e25">
							诊断不是目的，延寿才是
						</span>
					</div>
					<div
						class="top_description"
						style="color: rgba(0, 0, 0, 0.65)"
					>
						及时发现异常电池，实时保养和替换
						<br />
						为储能系统延寿，增加电站营收
					</div>
				</div>
				<div class="left">
					<img
						src="../../assets/imgs/img-doctor-picture-b.png"
						alt=""
					/>
				</div>
			</div>
		</div>
		<!-- footer -->
		<the-footer></the-footer>
	</div>
</template>

<script>
	export default {
		name: "doctor",
		data() {
			return {
				suanfa: [
					{ name: "SOC不一致性偏高", svg: "a-icon-doctor-a1x" },
					{ name: "SOC不一致性偏低", svg: "a-icon-doctor-b1x" },
					{ name: "电容量不足", svg: "a-icon-doctor-c1x" },
					{ name: "电池微短路", svg: "a-icon-doctor-d1x" },
					{ name: "电连接异常", svg: "a-icon-doctor-e1x" },
					{ name: "BMS采集故障", svg: "a-icon-doctor-f1x" }
				]
			};
		},
		methods: {
			booking() {
				this.$store.commit("setState", { visible: true });
			}
		}
	};
</script>

<style lang="scss">
	.doctor {
		.EMS_box {
			padding: px2rem(40) px2rem(24);
			.common_title {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding-bottom: px2rem(26);
				.cn_title {
					font-size: px2rem(20);
					font-family: PingFangSC, PingFangSC-Semibold;
					font-weight: 600;
					color: rgba(0, 0, 0, 0.85);
				}
				.en_title {
					font-size: px2rem(14);
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					color: rgba(0, 0, 0, 0.25);
					text-transform: uppercase;
					margin-top: px2rem(4);
				}
				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 2;
					content: "";
					display: block;
					width: 100%;
					height: px2rem(2);
					background-color: #e6e6e6;
				}
				&::before {
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: 3;
					content: "";
					display: block;
					width: px2rem(77);
					height: px2rem(2);
					background-color: #00c699;
				}
			}
			.align_center {
				align-items: center;
				&::after {
					left: 50%;
					transform: translateX(-50%);
				}
				&::before {
					left: 50%;
					transform: translateX(-50%);
				}
			}
			&_con0 {
				.zero_title {
					width: 100%;
					height: px2rem(128);
					line-height: px2rem(128);
					margin-bottom: px2rem(40);
					font-size: px2rem(20);
					font-family: PingFangSC, PingFangSC-Semibold;
					font-weight: 600;
					text-align: center;
					color: #ffffff;
					background-image: url("../../assets/imgs/img-doctor-data.png");
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					border-radius: px2rem(4);
				}
				.grid {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					grid-template-rows: repeat(3, 167);
					grid-gap: px2rem(8);
					margin-top: px2rem(24);
					&_item {
						background-color: #f4f5f7;
						padding: px2rem(32) px2rem(16);
						.svg {
							width: px2rem(56);
							height: px2rem(56);
							border-radius: 50%;
							background-color: #ffffff;
							display: flex;
							justify-content: center;
							align-items: center;
							margin: 0 auto;
							&_img {
								width: px2rem(40);
								height: px2rem(40);
							}
						}
						.bottom {
							margin-top: px2rem(16);
							padding-top: px2rem(8);
							@include thinBorder(top, #e6e6e6);
							font-size: px2rem(14);
							font-family: PingFangSC, PingFangSC-Medium;
							font-weight: 500;
							text-align: center;
							color: rgba(0, 0, 0, 0.85);
						}
					}
				}
			}
			&_con1 {
				display: flex;
				flex-direction: column;
				.top {
					font-size: px2rem(24);
					font-family: PingFangSC, PingFangSC-Medium;
					font-weight: 500;
					text-align: left;
					color: #0c0e25;
					&_description {
						font-size: px2rem(14);
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						text-align: left;
						color: rgba(255, 255, 255, 0.65);
						margin: px2rem(24) 0;
						line-height: 2;
					}
				}
				.bottom {
					height: px2rem(435);
				}
			}
		}
	}
</style>
